import { useEffect, useRef } from "react";
import { atom, useRecoilState } from "recoil";
import quotes from "../../quotes.json";
import { NameProvider } from "../atomUtils";

type QuoteAtomState = {
  at: Date | undefined;
  text: string | undefined;
};

/**
 * 10 Seconds
 */
export const timeBetweenQuotes = 10 * 1000;

const quoteNameProvider = new NameProvider("quote");

/**
 *
 * @param quotes
 * @param delay Delay should be in milliseconds
 * @returns
 */
function makeQuoteProvider(quotes: string[], delay: number = timeBetweenQuotes) {
  const quoteAtom = atom<QuoteAtomState>({
    key: quoteNameProvider.next(),
    default: {
      at: undefined,
      text: undefined,
    },
  });
  const useQuote = () => {
    let [{ at }, setState] = useRecoilState(quoteAtom);
    const textRef = useRef<string | undefined>(undefined);
    const now = new Date();
    useEffect(() => {
      if (!at || !textRef.current || now.getTime() - at.getTime() >= delay) {
        textRef.current = quotes[Math.floor(Math.random() * quotes.length)];
        setState({ text: textRef.current, at: now });
      }
    }, [at]);
    return textRef.current;
  };
  return useQuote;
}

export const useDefaultQuotes = makeQuoteProvider(quotes);
