import React from "react";
import { CircularProgress, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, CSSProperties } from "react";
import { useDefaultQuotes } from "../../../utils/quotes/quotesProvider";

type Props = {
  style?: CSSProperties;
  text?: string;
  loading?: boolean;
  children?: ReactNode;
};

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({    
  div: (props: Props) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
    ...props.style,
  }),
  // child: ({ style }: Props) => ({ ...style! }),
}));

const LoadingComponent = (props: Props) => {
  const classes = useStyles(props);
  return props.loading ? (
    <div className={classes.div}>
      <div style={{ textAlign: "center" }}>
        {props.text && <Typography variant="h2">{props.text}</Typography>}
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div style={props.style}>{props.children}</div>
  );
};

export default LoadingComponent;


export function RandomTextLoading(props: Omit<Props, "text">) {
  const text = useDefaultQuotes();
  return <LoadingComponent {...props} text={text} />;
}
